<template>
  <div class="Authority">
    <Header title="财务审核" index="首页" titleOne="财务管理" beforeTitle="财务审核" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-input
            v-model="reviewParams.company_name"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="review.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="contract_name" label="合同名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.contract_name ? scope.row.contract_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="product_name" label="项目名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.product_name ? scope.row.product_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="signatory" label="签单人" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.signatory ? scope.row.signatory : "-" }}</template>
        </el-table-column>
        <el-table-column prop="down_time" label="签单时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.down_time ? scope.row.down_time : "-" }}</template>
        </el-table-column>
        <el-table-column prop="receivables" label="应收款（元）" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.receivables ? scope.row.receivables : "-" }}</template>
        </el-table-column>
        <el-table-column prop="received" label="已收款（元）" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.received ? scope.row.received : "-" }}</template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="350px">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                退款金额： {{scope.row.refund_amount}}
                <br />
                退款原因： {{scope.row.refund_reason}}
              </div>
              <el-button class="edit" size="mini" @click="agree(scope.row.id)">同意退款</el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                退款金额： {{scope.row.refund_amount}}
                <br />
                退款原因： {{scope.row.refund_reason}}
              </div>
              <el-button class="edit" size="mini" @click="reject(scope.row.id)">拒绝退款</el-button>
            </el-tooltip>
            <el-button class="edit" size="mini" @click="detail(scope.row.id)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="review.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="reviewParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="reviewParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="review.total"
        ></el-pagination>
      </div>
      <el-dialog title="拒绝退款" :visible.sync="dialogFormVisible" width="400px">
        <el-form :model="form" :rules="formRule" ref="formRef" :label-position="labelPosition">
          <el-input type="textarea" :rows="2" placeholder="请输入退款原因" v-model="form.desc"></el-input>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onConfirm()">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      loading: true,
      reviewParams: {
        page: 1,
        limit: 10,
        company_name: "",
        order: "id desc",
      },
      dialogFormVisible: false,
      labelPosition: "top",
      visible: false,
      form: {
        desc: "",
      },
      formRule: {},
    };
  },
  activated () {
    // this.handleSearch();
    this.reviewParams
    this.$store.dispatch("getReviewList", this.reviewParams);
  },
  computed: {
    ...mapState(["review"]),
  },
  created () {
    this.$store.dispatch("getReviewList", this.reviewParams);
  },
  methods: {
    info (row) {
      console.log(row, '退款信息')
    },
    handleSizeChange (val) {
      this.reviewParams.limit = val;
      this.$store.dispatch("getReviewList", this.reviewParams);
    },
    handleCurrentChange (val) {
      this.reviewParams.page = val;
      this.$store.dispatch("getReviewList", { ...this.reviewParams });
    },
    handleSearch () {
      this.$store.dispatch("getReviewList", { ...this.reviewParams, page: '', limit: '' });
      this.reviewParams.page = 1
    },
    agree (id) {
      this.$confirm("请你再次确认同意退款，同意操作后不可撤回？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios.post("/api/finance/pass_refund", {
            id: id,
          }).then((res) => {
            this.$message({
              type: "success",
              message: res.message,
            });
            this.$store.dispatch("getReviewList", this.reviewParams);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退款",
          });
        });
    },
    reject (id) {
      this.dialogFormVisible = true;
      this.id = id;
    },
    onConfirm () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.axios
            .post("/api/finance/reject_refund", {
              reject_refund: this.id,
              id: this.id,
            })
            .then((res) => {
              console.log(res);
              this.$message.success("拒绝退款成功");
              this.reload();
            })
            .catch((res) => {
              console.log("err:", res);
            });
          this.dialogFormVisible = false;
        } else {
          console.log("输入错误");
        }
      });
    },
    detail (id) {
      this.$router.push("/finance/detail?id=" + id);
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>

<style scoped="scoped">
.whx {
  color: #ff6f7a;
  font-weight: bold;
}

.yhx {
  color: #cacddf;
  font-weight: bold;
}

.ytk {
  color: #ffbe2c;
  font-weight: bold;
}
</style>
